import { combineReducers } from "redux";

import { accountSettingsStore } from "./accountSettings";
import { chatStore } from "./chat";
import { notificationStore } from "./Notifications";
import { ongoingShiftStore } from "./ongoingShifts";
import { recentColleaguesStore } from "./recentColleagues";
import { session } from "./session";
import { shiftStore } from "./shift";
import { specialitiesStore } from "./specialities";
import { workerFriendsStore } from "./workerFriends";

export const rootReducer = combineReducers({
  session,
  shiftStore,
  specialitiesStore,
  accountSettingsStore,
  ongoingShiftStore,
  notificationStore,
  chatStore,
  recentColleaguesStore,
  workerFriendsStore,
});
