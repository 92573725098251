import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { uploadTimesheetToS3Storage } from "@src/app/utils/fileUpload";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { Shift, Timecard } from "@src/lib/interface";
import request from "superagent";

import { FetchAgentShiftRequest, RatingRequest } from "./model";
import { getAuthHeader } from "../../superagent";

export const fetchAgentShifts = async (query: FetchAgentShiftRequest): Promise<Shift[]> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/calendar/agentShifts`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query(query)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const createAccountLog = async (
  text: string,
  accountId: string,
  shiftId: string
): Promise<object> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/account-log/create`)
    .set(await getAuthHeader())
    .send({ text, accountID: accountId, addedBy: accountId, meta: { shiftId } })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

interface UploadTimecardResponse {
  status: Number;
  body: {
    response: {
      timecard: Timecard;
    };
  };
}

// FIXME: We don't save timecard on offline mode, to receive Instantpay, HCP needs be online to upload timecard
// other usage on page `/myShift/:shiftId/signature`, we don't load this page See also #3520
export const ongoingShiftsUploadTimecard = async (
  selectedFile: SelectedFile,
  _id: string,
  locationType: string,
  appType: string,
  connectivityMode: string
): Promise<UploadTimecardResponse> => {
  const { type, fileBlob } = selectedFile;
  if (!fileBlob) {
    throw new Error("fileBlob not found");
  }
  const fileUploadResult = await uploadTimesheetToS3Storage(fileBlob, type, _id);

  return await request
    .put(`${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/timecard/${_id}`)
    .set(await getAuthHeader())
    .send({
      timecard: fileUploadResult,
      locationType,
      appType,
      connectivityMode,
    })
    .then(({ body, status }) => ({ ...body.timecard, status }))
    .catch(logApiFailureEvent);
};

export const addRating = async (req: RatingRequest): Promise<Shift> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/rating`)
    .set(await getAuthHeader())
    .send({ reviewFor: "FACILITY", ...req })
    .then(({ body, status }) => ({ ...body, status }))
    .catch(logApiFailureEvent);
};
