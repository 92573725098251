import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

interface IRecentColleagueInfo {
  colleagueUserId: string;
  colleagueName: string;
  shiftStart: string;
  facilityName: string;
  shiftName: ShiftPeriod;
}
interface RecentColleaguesStore {
  loading: boolean;
  workers: Array<IRecentColleagueInfo> | null;
}

const ActionType = {
  UPDATE_RECENT_COLLEAGUES: "UPDATE_RECENT_COLLEAGUES",
};

export { ActionType, RecentColleaguesStore, IRecentColleagueInfo };
