import { ActionType, RecentColleaguesStore } from "./model";

const initialState: RecentColleaguesStore = {
  loading: false,
  workers: null,
};

const recentColleaguesStore = (state = initialState, action): RecentColleaguesStore => {
  switch (action.type) {
    case ActionType.UPDATE_RECENT_COLLEAGUES:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export { recentColleaguesStore };
